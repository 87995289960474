import React, { Fragment, useEffect, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXDoubleButtonFooter from "../../components/layout/footer/hailcx-double-button-footer"
import {
  EuiConfirmModal,
  EuiDescriptionList,
  EuiImage,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
} from "@elastic/eui"
import Images from "../../images"
import { navigate } from "gatsby-link"
import { observer } from "mobx-react-lite"
import { useStore } from "../../stores"
import { RepairTypes } from "../../entities/constants"
import SummaryItem from "../../entities/summaryItems"
import "../../styles/hailcx-description-lists.css"
import {
  getBookingDateTimeUIFormatted,
  getCompletionDateUIFormatted,
} from "../../entities/utilities"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import SummaryRepairLocation from "../../components/booking/summary-repairlocation"

const WaitlistOffer = () => {
  const [bookingSummary, setBookingSummary] = useState<SummaryItem[]>([])
  const { facilityStore, bookingStore, customerStore, waitlistStore } =
    useStore()
  const { facility } = facilityStore
  const { booking, setBookingNumber } = bookingStore
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)

  const location = useLocation()
  const queriedString = queryString.parse(location.search)
  const { eqn, offerId } = queriedString

  const closeModal = () => setIsModalVisible(false)
  const handleReject = () => {
    waitlistStore.rejectOffer(waitlistStore.currentOffer?.offerId || "")
    setIsCancelled(true)
    closeModal()
    navigate(
      `/book/manage-booking/?eqn=${customerStore.encryptedQuoteNumber}&bookingNumber=${bookingStore.bookingNumber}`
    )
  }

  const data = {
    title: "Waitlist",
    primaryContent: "See new booking times",
    secondaryContent: "Keep my current booking",
  }

  
  let modal = (
    <EuiConfirmModal
      title="Are you sure?"
      onCancel={closeModal}
      onConfirm={handleReject}
      cancelButtonText="Go back"
      confirmButtonText="Confirm"
      defaultFocusedButton="confirm"
    >
      <p>You will not be able to see this offer again.</p>
    </EuiConfirmModal>
  )

  const handlePrimaryClick = () => {
    navigate("/book/my-offers")
  }

  const handleSecondaryClick = () => {
    setIsModalVisible(true)
  }

  return (
    <div>No longer in use with new version of waitlist.</div>
  )
}

export default observer(WaitlistOffer)
